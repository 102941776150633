import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MainCta from '../components/main-cta'
import { Articles } from "../components/articles"
import { rhythm } from "../utils/typography"

const Home = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <MainCta />
      <Layout location={location} title={siteTitle} hideHeader>
        <SEO title="Home" />
        <h2 style={{
          marginTop: '24px'
        }}>Latest articles</h2>
        <Articles posts={posts} />
        <p style={{marginTop: rhythm(2)}}>
          <Link to="/articles"><span role="img" aria-label="books">📚</span> All articles</Link>
        </p>
      </Layout>
    </>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            draft
          }
        }
      }
    }
  }
`
