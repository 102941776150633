import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import * as classes from "./main-cta.module.css"
import Image from "gatsby-image"

const MainCta = () => {
  const data = useStaticQuery(graphql`
    query MainCta {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <section className={classes.description}>
          <Image
            className={classes.img}
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
          />
          <p>
            Hi there{" "}
            <span role="img" aria-label="Hand wave">
              👋
            </span>{" "}
            I'm{" "}
            <a
              href={`https://twitter.com/${social.twitter}`}
              target="_blank"
              rel="noreferrer"
            >
              Alex
            </a>
          </p>
          <h2>
            I write short visual
            <br />
            React articles
          </h2>
        </section>
        <section className={classes.form}>
          <p>
            Subscribe to get notified
            <br />
            when a new one comes out
          </p>
          <form
            action="https://app.convertkit.com/forms/2821475/subscriptions"
            class="seva-form formkit-form"
            method="post"
            data-sv-form="2821475"
            data-uid="fbdf17662f"
            data-format="inline"
            data-version="5"
            data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null,"sparkloop":null,"googletagmanager":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
          >
            <input
              type="text"
              placeholder="First name"
              name="fields[first_name]"
            />
            <input
              type="email"
              required
              placeholder="Email address"
              name="email_address"
            />
            <button type="submit">Subscribe</button>
          </form>
        </section>
      </div>
    </div>
  )
}

export default MainCta
